/*eslint-disable*/

// reactstrap components
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © {new Date().getFullYear()}{" "}
            <a
              className="font-weight-bold ml-1"
              href="https://mognos.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Mognos&nbsp;
            </a>
            &nbsp;desenvolvido por
            <a href="https://aute.dev" target="_blank">
              Aute
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink
                href="https://mognos.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Mognos
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://www.mognos.com/index.html#sobre-nos"
                rel="noopener noreferrer"
                target="_blank"
              >
                Sobre nós
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
