import axios from "axios";
import { logoutUser } from "./user.services";

async function logout() {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/api/auth/logout`,
    {}
  );

  if (response.status === 200) {
    logoutUser();
    setTimeout(() => {
      window.location.href = "/auth/login";
    }, 1000);
  }
  window.location.reload();
  return response;
}

export { logout };
