// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
// core components
import axios from "axios";
import UserHeader from "components/Headers/UserHeader.js";
import { useState } from "react";
import { toast } from "react-toastify";
import { getAllData, getUser } from "services/user.services";
import { useNavigate } from "react-router-dom";

const RuralProperties = () => {
  const navigate = useNavigate();
  const [ruralProperties, setRuralProperties] = useState(getUser()?.imoveis);
  const [cpfcnpj, setCpfCnpj] = useState(getUser()?.cpfcnpj);
  const [nomeProprietario, setNomeProprietario] = useState(getUser()?.nomecompleto);
  const [matricula, setMatricula] = useState("");
  const [codigoImovel, setCodigoImovel] = useState("");
  const [nomeFazenda, setNomeFazenda] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [numeroCarEstadual, setNumeroCarEstadual] = useState("");
  const [numeroCarFederal, setNumeroCarFederal] = useState("");
  const [idRuralProperties, setIdRuralProperties] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  
  const handleRegisterNewData = async (event) => {
    event.preventDefault();

    let ruralProperties = {
      cpfcnpj: cpfcnpj,
      nome_proprietario: nomeProprietario,
      matricula: matricula,
      codigo_imovel: codigoImovel,
      nome_fazenda: nomeFazenda,
      municipio: municipio,
      numero_car_estadual: numeroCarEstadual,
      numero_car_federal: numeroCarFederal,
    };

    try {
      if (isEdit) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/imoveis/${idRuralProperties}`,
          ruralProperties
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/imoveis/`,
          ruralProperties
        );
      }

      toast.success(`Imóvel rural ${isEdit ? 'alterado' : 'cadastrado'} com sucesso.`);
      await handleCancel();
      setMatricula("");
      await getAllData();
      setRuralProperties(getUser()?.imoveis);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message || error.response.data.error}`);
      }
      console.error("Erro:", error);
    }
  };

  const handleDelete = async (id_ruralProperties) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/imoveis/${id_ruralProperties}`
      );
      toast.success(`Imóvel rural deletado com sucesso.`);
      await getAllData();
      setRuralProperties(getUser()?.imoveis);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  const initChange = async (ruralPropertie) => {
      setIsEdit(true);
      setCpfCnpj(ruralPropertie.cpfcnpj);
      setNomeProprietario(ruralPropertie.nome_proprietario);
      setMatricula(ruralPropertie.matricula);
      setCodigoImovel(ruralPropertie.codigo_imovel);
      setNomeFazenda(ruralPropertie.nome_fazenda);
      setMunicipio(ruralPropertie.municipio);
      setNumeroCarEstadual(ruralPropertie.numero_car_estadual);
      setNumeroCarFederal(ruralPropertie.numero_car_federal);
      setIdRuralProperties(ruralPropertie.id);
  };

  const handleCancel = async () => {
    setIsEdit(false);
    setCpfCnpj(getUser()?.cpfcnpj);
    setNomeProprietario(getUser()?.nomecompleto);
    setMatricula("");
    setCodigoImovel("");
    setNomeFazenda("");
    setMunicipio("");
    setNumeroCarEstadual("");
    setNumeroCarFederal("");
    setIdRuralProperties(null);
  };



  const ruralPropertiesDocuments = (ruralPropertie) => {
    navigate(`/admin/user-documents/${ruralPropertie.id}/${ruralPropertie.nome_fazenda ? ruralPropertie.nome_fazenda : ruralPropertie.id}`);
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleRegisterNewData}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Imóveis rurais</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cpf-cnpj"
                          >
                            CPF/CNPJ
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={cpfcnpj}
                            id="input-cpf-cnpj"
                            placeholder="000.000.000-00"
                            type="text"
                            onChange={(e) => setCpfCnpj(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-proprietario"
                          >
                            Nome do proprietário
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nome-proprietario"
                            type="text"
                            value={nomeProprietario}
                            onChange={(e) => setNomeProprietario(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-matricula"
                          >
                            Matrícula
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-matricula"
                            type="text"
                            value={matricula}
                            onChange={(e) => setMatricula(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-codigo-imovel"
                          >
                            Código do imóvel
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-codigo-imovel"
                            type="text"
                            value={codigoImovel}
                            onChange={(e) => setCodigoImovel(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-fazenda"
                          >
                            Nome da fazenda
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nome-fazenda"
                            type="text"
                            value={nomeFazenda}
                            onChange={(e) => setNomeFazenda(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-municipio"
                          >
                            Município
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-municipio"
                            type="text"
                            value={municipio}
                            onChange={(e) => setMunicipio(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-numero-car-estadual"
                          >
                            Número do CAR (estadual)
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-numero-car-estadual"
                            type="text"
                            value={numeroCarEstadual}
                            onChange={(e) => setNumeroCarEstadual(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-numero-car-federal"
                          >
                            Número do CAR (federal)
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-numero-car-federal"
                            type="text"
                            value={numeroCarFederal}
                            onChange={(e) => setNumeroCarFederal(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {isEdit ? (
                      <>
                      <Button color="success" type="submit" className="ml-auto mr-2">
                        Alterar
                      </Button>
                      <Button color="warning" type="button" className="ml-auto" onClick={handleCancel}>
                        Cancelar
                      </Button>
                      </>
                    ) : (
                      <Button color="info" type="submit" className="ml-auto">
                        Salvar
                      </Button>
                    )}
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Imóveis rurais</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <colgroup>
                  <col style={{ width: "5%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col
                    style={{ width: "3%", textAlign: "center", color: "red" }}
                  />
                </colgroup>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">CPF/CNPJ</th>
                    <th scope="col">Nome do proprietário</th>
                    <th scope="col">Matrícula</th>
                    <th scope="col">Código do imóvel</th>
                    <th scope="col">Nome da fazenda</th>
                    <th scope="col">Município</th>
                    <th scope="col">Número do CAR (estadual)</th>
                    <th scope="col">Número do CAR (federal)</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {ruralProperties && ruralProperties.length ? (
                    ruralProperties.map((ruralPropertie, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            color: "blue",
                          }}
                        >
                          <i
                            className="fas fa-file-text cursor-pointer"
                            onClick={() => ruralPropertiesDocuments(ruralPropertie)}
                          />
                        </td>
                        <th scope="row">{ruralPropertie.cpfcnpj}</th>
                        <td>{ruralPropertie.nome_proprietario}</td>
                        <td>{ruralPropertie.matricula}</td>
                        <td>{ruralPropertie.codigo_imovel}</td>
                        <td>{ruralPropertie.nome_fazenda}</td>
                        <td>{ruralPropertie.municipio}</td>
                        <td>{ruralPropertie.numero_car_estadual}</td>
                        <td>{ruralPropertie.numero_car_federal}</td>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                          }}
                        >
                        <i
                          className="fas fa-edit cursor-pointer mr-3"
                          style={{
                            color: "blue",
                          }}
                          onClick={() => initChange(ruralPropertie)}
                        />
                        <i
                          className="fas fa-trash cursor-pointer"
                          style={{
                            color: "red",
                          }}
                          onClick={() => handleDelete(ruralPropertie.id)}
                        />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7}
                        style={{
                          width: "5%",
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bolder",
                        }}
                      >
                        Nenhum registro!
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RuralProperties;
