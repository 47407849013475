// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { getUser, getAllData } from "services/user.services";
import SelectEstado from "../../components/SelectEstado";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";

const UsersList = () => {
  const [allUsers, setAllUsers] = useState("");
  const [enderecos, setEnderecos] = useState(getUser().enderecos);
  const [nomeEndereco, setNomeEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");

  const handleRegisterNewData = async (event) => {
    event.preventDefault();

    let user = {
      nome: nomeEndereco,
      cep: cep,
      estado: estado,
      cidade: cidade,
      bairro: bairro,
      rua: rua,
      numero: numero,
      complemento: complemento,
    };
  };

  const handleToggleAtiveUser = async (id_endereco, isUserAtive) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/usuarios/${id_endereco}`
      );
      toast.success(`Estado do usuário alterado com sucesso.`);
      await getAllUsers();
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  const handleLogin = async (email) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login-user-with-admin`,
        {
          email: email,
          senha: "",
        }
      );
      toast.success(`Usuário logado com sucesso.`);
      await getAllData();
      window.location.href = window.location.href;
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/todos/`
      );
      setAllUsers(response.data);
    } catch {}
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Lista de usuários</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <colgroup>
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "3%" }} />
                  <col style={{ width: "3%" }} />
                  <col
                    style={{ width: "2%", textAlign: "center", color: "red" }}
                  />
                </colgroup>
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Nome</th>
                    <th scope="col">Sobrenome</th>
                    <th scope="col">CPF/CNPJ</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Tipo</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {allUsers && allUsers.length ? (
                    allUsers.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            color: "blue",
                          }}
                        >
                          <i
                            className="fas fa-sign-in cursor-pointer"
                            onClick={() => handleLogin(user.email)}
                          />
                        </td>
                        <th scope="row">{user.nome}</th>
                        <td>{user.sobrenome}</td>
                        <td>{user.cpfcnpj}</td>
                        <td>{user.email}</td>
                        <td>{user.telefone}</td>
                        <td>{user.tipo}</td>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            color: user.ativo ? "red" : "gray",
                          }}
                        >
                          <i
                            className="fas fa-power-off cursor-pointer"
                            onClick={() =>
                              handleToggleAtiveUser(user.id, user.ativo)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7}
                        style={{
                          width: "5%",
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bolder",
                        }}
                      >
                        Nenhum registro!
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UsersList;
