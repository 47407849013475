// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { getUser } from "../../services/user.services";

const UserHeader = () => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "250px",
          backgroundImage:
            "url(" +
            require("../../assets/img/brand/logo-mognos-sem-fundo.png") +
            ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="7" md="10">
              <h1 className="display-2 text-white">Olá {getUser()?.nome}</h1>
              <p className="text-white mt-0 mb-5">
                Esse é o seu perfil, você pode alterar seus dados ou colocar
                novas fotos. Após alterar, clique em editar perfil para salvar.
              </p>
              {/* <Button
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                Editar perfil
              </Button> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
