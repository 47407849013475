import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import RecoverPassword from "views/examples/RecoverPassword.js";
import Address from "views/examples/Address";
import Occupation from "views/examples/Occupation";
import Document from "views/examples/Document";
import RuralProperties from "views/examples/RuralProperties";
import UsersList from "views/examples/UsersList";
import { getUser } from "services/user.services";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Index />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: <Icons />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: <Maps />,
  //   layout: "/admin",
  // },
  {
    path: "/user-profile",
    name: "Perfil de usuário",
    icon: "ni ni-single-02 text-blue",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/user-address",
    name: "Endereço",
    icon: "ni ni-pin-3 text-orange",
    component: <Address />,
    layout: "/admin",
  },
  // {
  //   path: "/user-contact",
  //   name: "Contato",
  //   icon: "ni ni-email-83 text-success",
  //   component: <Contact />,
  //   layout: "/admin",
  // },
  {
    path: "/user-occupation",
    name: "Ocupação",
    icon: "fas fa-wrench text-info",
    component: <Occupation />,
    layout: "/admin",
  },
  {
    path: "/user-documents/:id_imovel/:nameRuralPropertie",
    name: "Documentos",
    icon: "fas fa-id-card text-purple",
    component: <Document />,
    layout: "/admin",
    hide: true,
  },
  {
    path: "/user-documents/",
    name: "Documentos",
    icon: "fas fa-id-card text-purple",
    component: <Document />,
    layout: "/admin",
  },
  // {
  //   path: "/user-business",
  //   name: "Empresas",
  //   icon: "fas fa-building text-gray-dark",
  //   component: <Business />,
  //   layout: "/admin",
  // },
  {
    path: "/rural-properties",
    name: "Imóveis rurais",
    icon: "fas fa-home text-yellow",
    component: <RuralProperties />,
    layout: "/admin",
  },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    // name: "Login",
    // icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    hide: true,
  },
  {
    path: "/register",
    // name: "Register",
    // icon: "ni ni-circle-08 text-pink",
    component: <Register />,
    layout: "/auth",
    hide: true,
  },
  {
    path: "/recover-password",
    // name: "Recuperar senha",
    // icon: "ni ni-circle-08 text-pink",
    component: <RecoverPassword />,
    layout: "/auth",
    hide: true,
  },
];

if (getUser() !== null && getUser().tipo === "admin") {
  routes.push({
    path: "/users-list",
    name: "Lista de usuários",
    icon: "fas fa-users text-blue",
    component: <UsersList />,
    layout: "/admin",
  });
}
export default routes;
