import axios from "axios";

function addUser(user) {
  user.dataNascimento = user.dataNascimento ? new Date(user.dataNascimento)
    .toISOString()
    .split("T")[0] : null;
  user.nomecompleto = user.sobrenome ? user.nome + " " + user.sobrenome : user.nome;
  const jsonUser = JSON.stringify(user);
  localStorage.setItem("user", jsonUser);
}

function getUser() {
  const jsonUser = localStorage.getItem("user");
  const jsonUserDecrypt = JSON.parse(jsonUser);
  if (jsonUserDecrypt && jsonUserDecrypt.nome && jsonUserDecrypt.cpf) {
    window.location.pathname = "/auth/login";
  }
  return jsonUser ? JSON.parse(jsonUser) : null;
}

function getImgProfileUser() {
  const jsonUser = localStorage.getItem("user");
  if (
    JSON.parse(jsonUser)?.img &&
    JSON.parse(jsonUser)?.imgs[0] &&
    JSON.parse(jsonUser)?.imgs[0].tipo === "perfil"
  )
    return JSON.parse(jsonUser)?.imgs[0];
  else {
    return {
      pathCompleto: require("../assets/img/brand/icon-mognos-c-fundo.png"),
    };
  }
}

async function getAllData(callback) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/usuarios/todos-meus-dados/`
    );
    addUser(response.data);
    if (callback) {
      callback();
    }
  } catch {}
}

function logoutUser() {
  localStorage.removeItem("user");
  window.location.href = "/auth/login";
}

export { addUser, getUser, getImgProfileUser, logoutUser, getAllData };
