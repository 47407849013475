import { Input } from "reactstrap";
import React from "react";

const SelectPaises = ({ selectedValue, onValueChange }) => {
  const handleChange = (event) => {
    onValueChange(event);
  };
  selectedValue = selectedValue || "";
  const countries = [
    "Afeganistão",
    "Albânia",
    "Argélia",
    "Andorra",
    "Angola",
    "Antígua e Barbuda",
    "Argentina",
    "Armênia",
    "Austrália",
    "Áustria",
    "Azerbaijão",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbados",
    "Bielorrússia",
    "Bélgica",
    "Belize",
    "Benin",
    "Butão",
    "Bolívia",
    "Bósnia e Herzegovina",
    "Botswana",
    "Brasil",
    "Brunei",
    "Bulgária",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Camboja",
    "Camarões",
    "Canadá",
    "República Centro-Africana",
    "Chade",
    "Chile",
    "China",
    "Colômbia",
    "Comores",
    "Congo",
    "República Democrática do Congo",
    "Costa Rica",
    "Croácia",
    "Cuba",
    "Chipre",
    "República Tcheca",
    "Dinamarca",
    "Djibuti",
    "Dominica",
    "República Dominicana",
    "Timor-Leste",
    "Equador",
    "Egito",
    "El Salvador",
    "Guiné Equatorial",
    "Eritreia",
    "Estônia",
    "Eswatini",
    "Etiópia",
    "Fiji",
    "Finlândia",
    "França",
    "Gabão",
    "Gâmbia",
    "Geórgia",
    "Alemanha",
    "Gana",
    "Grécia",
    "Granada",
    "Guatemala",
    "Guiné",
    "Guiné-Bissau",
    "Guiana",
    "Haiti",
    "Honduras",
    "Hungria",
    "Islândia",
    "Índia",
    "Indonésia",
    "Irã",
    "Iraque",
    "Irlanda",
    "Israel",
    "Itália",
    "Jamaica",
    "Japão",
    "Jordânia",
    "Cazaquistão",
    "Quênia",
    "Kiribati",
    "Coreia do Norte",
    "Coreia do Sul",
    "Kosovo",
    "Kuwait",
    "Quirguistão",
    "Laos",
    "Letônia",
    "Líbano",
    "Lesoto",
    "Libéria",
    "Líbia",
    "Liechtenstein",
    "Lituânia",
    "Luxemburgo",
    "Madagáscar",
    "Malawi",
    "Malásia",
    "Maldivas",
    "Mali",
    "Malta",
    "Ilhas Marshall",
    "Mauritânia",
    "Maurícia",
    "México",
    "Micronésia",
    "Moldávia",
    "Mônaco",
    "Mongólia",
    "Montenegro",
    "Marrocos",
    "Moçambique",
    "Mianmar",
    "Namíbia",
    "Nauru",
    "Nepal",
    "Países Baixos",
    "Nova Zelândia",
    "Nicarágua",
    "Níger",
    "Nigéria",
    "Macedônia do Norte",
    "Noruega",
    "Omã",
    "Paquistão",
    "Palau",
    "Panamá",
    "Papua-Nova Guiné",
    "Paraguai",
    "Peru",
    "Filipinas",
    "Polônia",
    "Portugal",
    "Catar",
    "Romênia",
    "Rússia",
    "Ruanda",
    "Santa Lúcia",
    "São Vicente e Granadinas",
    "Samoa",
    "San Marino",
    "São Tomé e Príncipe",
    "Arábia Saudita",
    "Senegal",
    "Sérvia",
    "Seychelles",
    "Serra Leoa",
    "Singapura",
    "Eslováquia",
    "Eslovênia",
    "Ilhas Salomão",
    "Somália",
    "África do Sul",
    "Sudão do Sul",
    "Espanha",
    "Sri Lanka",
    "Sudão",
    "Suriname",
    "Suécia",
    "Suíça",
    "Síria",
    "Taiwan",
    "Tajiquistão",
    "Tanzânia",
    "Tailândia",
    "Togo",
    "Tonga",
    "Trinidad e Tobago",
    "Tunísia",
    "Turquia",
    "Turcomenistão",
    "Tuvalu",
    "Uganda",
    "Ucrânia",
    "Emirados Árabes Unidos",
    "Reino Unido",
    "Estados Unidos",
    "Uruguai",
    "Uzbequistão",
    "Vanuatu",
    "Vaticano",
    "Venezuela",
    "Vietnã",
    "Iémen",
    "Zâmbia",
    "Zimbábue",
  ];

  return (
    <div>
      <Input
        className="mb-3"
        type="select"
        name="selectPaises"
        id="selectPaises"
        value={selectedValue}
        onChange={handleChange}
      >
        <option value="">Selecione...</option>
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
        <option value="Outro">Outro</option>
      </Input>
    </div>
  );
};

export default SelectPaises;
