/*eslint-disable*/

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.mognos.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Mognos
                </a>
                &nbsp;desenvolvido por
                <a href="https://aute.dev" target="_blank">
                  &nbsp;Aute
                </a>
              </div>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end">
                <NavItem>
                  <NavLink
                    href="https://www.mognos.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-muted"
                  >
                    Mognos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://www.mognos.com/index.html#sobre-nos"
                    target="_blank"
                    className="text-muted"
                  >
                    Sobre nós
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
