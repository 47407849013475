// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { getUser, getAllData } from "services/user.services";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";

const Occupation = () => {
  const [ocupations, setOcupations] = useState(getUser()?.ocupacoes);
  const [descricao, setDescricao] = useState("");

  const handleRegisterNewData = async (event) => {
    event.preventDefault();

    let occupation = {
      descricao: descricao,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/ocupacoes/`,
        occupation
      );
      toast.success(`Dados alterados com sucesso.`);
      await getAllData();
      setOcupations(getUser()?.ocupacoes);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  const handleDelete = async (id_ocupation) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/ocupacoes/${id_ocupation}`
      );
      toast.success(`Endereço deletado com sucesso.`);
      await getAllData();
      setOcupations(getUser()?.ocupacoes);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleRegisterNewData}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Profissões</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-endereco"
                          >
                            Profissões
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nome-endereco"
                            placeholder="Principal"
                            type="text"
                            onChange={(e) => setDescricao(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="info" type="submit" className="ml-auto">
                      Adicionar
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Profissões adicionadas</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <colgroup>
                  <col style={{ width: "95%" }} />
                  <col
                    style={{ width: "5%", textAlign: "center", color: "red" }}
                  />
                </colgroup>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Descrição</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {ocupations && ocupations.length ? (
                    ocupations.map((ocupation, index) => (
                      <tr key={index}>
                        <th scope="row">{ocupation.descricao}</th>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            color: "red",
                          }}
                        >
                          <i
                            className="fas fa-trash cursor-pointer"
                            onClick={() => handleDelete(ocupation.id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{
                          width: "5%",
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bolder",
                        }}
                      >
                        Nenhum registro!
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Occupation;
