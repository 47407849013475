// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { getUser, getAllData } from "services/user.services";
import SelectEstado from "../../components/SelectEstado";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";

const Address = () => {
  const [enderecos, setEnderecos] = useState(getUser()?.enderecos);
  const [nomeEndereco, setNomeEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");

  const handleRegisterNewData = async (event) => {
    event.preventDefault();

    let address = {
      nome: nomeEndereco,
      cep: cep,
      estado: estado,
      cidade: cidade,
      bairro: bairro,
      rua: rua,
      numero: numero,
      complemento: complemento,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/enderecos/`,
        address
      );
      toast.success(`Endereço cadastrado com sucesso.`);
      await getAllData();
      setEnderecos(getUser()?.enderecos);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  const handleDelete = async (id_endereco) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/enderecos/${id_endereco}`
      );
      toast.success(`Endereço deletado com sucesso.`);
      await getAllData();
      setEnderecos(getUser()?.enderecos);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleRegisterNewData}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Endereço</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-endereco"
                          >
                            Nome do Endereço
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nome-endereco"
                            placeholder="Principal"
                            type="text"
                            onChange={(e) => setNomeEndereco(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cep"
                          >
                            CEP
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-cep"
                            placeholder="00000000"
                            type="text"
                            onChange={(e) => setCep(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-select-estado"
                          >
                            Estado
                          </label>
                          <SelectEstado
                            id="input-select-estado"
                            selectedValue={estado}
                            onValueChange={(e) => {
                              setEstado(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cidade"
                          >
                            Cidade
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-cidade"
                            placeholder="São Paulo"
                            type="text"
                            onChange={(e) => setCidade(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bairro"
                          >
                            Bairro/Distrito
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bairro"
                            placeholder="Copacabana"
                            type="text"
                            onChange={(e) => setBairro(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome"
                          >
                            Logradouro
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-nome"
                            placeholder="Rua das Palmeiras"
                            type="text"
                            onChange={(e) => setRua(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-numero"
                          >
                            Número
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-numero"
                            placeholder="32"
                            type="text"
                            onChange={(e) => setNumero(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-complemento"
                          >
                            Complemento
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-complemento"
                            placeholder="AP 03"
                            type="text"
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="info" type="submit" className="ml-auto">
                      Salvar
                    </Button>
                  </div>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Endereços</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "35%" }} />
                  <col style={{ width: "2%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "10%" }} />
                  <col
                    style={{ width: "3%", textAlign: "center", color: "red" }}
                  />
                </colgroup>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Logradouro</th>
                    <th scope="col">Número</th>
                    <th scope="col">Bairro/Distrito</th>
                    <th scope="col">Complemento</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">CEP</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {enderecos && enderecos.length ? (
                    enderecos.map((endereco, index) => (
                      <tr key={index}>
                        <th scope="row">{endereco.nome}</th>
                        <td>{endereco.rua}</td>
                        <td>{endereco.numero}</td>
                        <td>{endereco.bairro}</td>
                        <td>{endereco.complemento}</td>
                        <td>{endereco.cidade}</td>
                        <td>{endereco.cep}</td>
                        <td
                          style={{
                            width: "5%",
                            textAlign: "center",
                            color: "red",
                          }}
                        >
                          <i
                            className="fas fa-trash cursor-pointer"
                            onClick={() => handleDelete(endereco.id)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={7}
                        style={{
                          width: "5%",
                          textAlign: "center",
                          color: "red",
                          fontWeight: "bolder",
                        }}
                      >
                        Nenhum registro!
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Address;
